<template>
  <home-page v-if="!loading" :page_data="page_data"/>
</template>
<script>
import HomePage from "@/components/HomePage";
import {get_created_page} from "@/api/admin";
const {API} = require('../env');

export default {
  components:{HomePage},
  data:() => ({
    page_data: null,
    loading: true,
  }),
  mounted() {
    this.link = API;
    get_created_page(this.$route.params.name)
        .then(response => {
          this.page_data = response.page[0];
          this.loading = false;
        }).then(() => {
      // document.title = this.page_data.head;
    }).catch(() =>{});

  }
}
</script>
