<template>
  <section class="home">
    <LoadingSpinner v-if="!page_data"/>
    <div class="my_container" v-else>
      <v-row>
        <v-col md="12" lg="9" >
          <!-- page head title -->
          <div class="title">
            <h1> {{ page_data.head }} </h1>
            <p class="the_date"> {{get_date}} </p>
          </div>
          <!-- story -->
          <div class="story">
            <!-- details -->
            <Static v-if="$route.path === '/'"/>
            <Daynamic :theData="page_data" v-else></Daynamic>
            <!-- end details -->


            <section class="pr-3 f_bold flast" style="font-size: 15px">
              <p>
                وبعد انتشار هذه الحادثة واجهت الشركه طلب كبير وغير متوقع فى عدد المستثمرين وعلمنا انهم سوف يغلقون أبواب التسجيل خلال <span class="f_bold f-17 red" style="font-weight: bold">
                <vue-countdown class="mb-0 font-weight-bold" style="display: inline-block; direction: ltr; margin-bottom: 0"  :time="1000000" :interval="100" tag="p">
                <template slot-scope="props"> {{ props.minutes }}:{{ props.seconds }}:0{{( Math .floor(props.milliseconds / 100)) }} </template>
              </vue-countdown >
              </span> إذا كنت تريد التسجيل بالشركه فعليك تجهيز مبلغ استثماري بما يقارب 2200 ريال وترك بياناتك لعلك تكون من المقبولين وتتمنى الجريده الربح الوفير للجميع
              </p>
            </section>
            <!-- start questions -->
            <Questions/>
            <!-- end questions -->
            <v-lazy
                v-model="isActive"
                :options="{
                threshold: .5
              }"
                min-height="50"
                transition="fade-transition"
            >
              <!-- start comments -->
              <comments/>
            </v-lazy>
          </div>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>

export default {
  data: () => ({
    isActive: false,
  }),
  name: 'Home',
  props:['page_data'],
  components: {
    Daynamic: () => import('@/components/Daynamic'),
    Static:() => import('@/components/Static'),
    LoadingSpinner: () => import('@/components/LoadingSpinner'),
    TheForm: () => import('@/components/theForm'),
    LoadingBefore: () => import('@/components/LoadingBeforeRegister/LoadingBefore'),
    Comments: () => import('@/components/comments/Comments'),
    Questions: () => import('@/components/qu/Questions'),
    VueCountdown: () => import('@chenfengyuan/vue-countdown')
  },
  computed: {
    get_date() {
      return this.$store.getters.getDate
    },
  },
  created() {
    document.title = this.page_data.head;
  }
}
</script>